<template>
  <el-dialog
      :visible="visible"
      width="510px"
      top="150px"
      close-on-press-escape
      :before-close="handleClose"
      class="add-course-for-user">
    <template slot="title">
      <div class="add-course-for-user__header">
        <img class="add-course-for-user__icon" src="@/assets/img/admin/users/add-course.svg" alt="icon">
        <div class="add-course-for-user__title">Добавить курс</div>
      </div>
      <el-divider/>
    </template>
    <div class="add-course-for-user__content">
      <form-input label="Имя пользователя" v-model="userName" disabled/>
      <form-select placeholder="Выберите курс"
                   label="Курс"
                   v-model="selectedCourse"
                   :validator="$v.selectedCourse"
                   :disable-options="userCourse"
                   :options="courses"/>
      <button @click="addCourseForUser" class="add-course-for-user__btn">
        <img src="@/assets/img/button/icon-check.svg" alt="">
        Сохранить
      </button>
    </div>
  </el-dialog>
</template>

<script>

import FormInput from "../../../../components/FormInput";
import FormSelect from "../../../../components/FormSelect";
import {validationMixin} from "vuelidate";
import {required} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";

import {userService} from "../user.service";

export default {
  name: "AddCourseForUser",
  mixins: [validationMixin],
  components: {
    FormInput,
    FormSelect
  },
  data() {
    return {
      visible: false,
      userId: null,
      userName: null,
      userCourse: [],
      selectedCourse: [],
      courses: []
    }
  },
  mounted() {
    userService.getCoursesForSelect().then((courses) => {
      this.courses = courses
    })
  },
  methods: {
    openModal(userInfo) {
      this.userCourse = userInfo.courses.map(course => {
        return {
          id: course.courseId
        }
      })
      this.userName = userInfo.fullName
      this.userId = userInfo.id
      this.visible = true
    },
    handleClose() {
      this.visible = false
      this.selectedCourse = []
      this.$v.$reset()
    },
    addCourseForUser() {
      validate(this.$v)
      if (validate(this.$v)) {
        this.$emit('addCourse', {userId: this.userId, courseIds: this.selectedCourse})
        this.handleClose()
      }
    }
  },
  validations: {
    selectedCourse: {required},
  }
}
</script>

<style lang="scss">
.add-course-for-user {

  &__header {
    display: flex;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #121212;

    img {
      margin-right: 10px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 195px;
    height: 48px;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;
    background: #4EE1A3;
    border-radius: 100px;

    cursor: pointer;

    img {
      margin-right: 7px;
    }
  }

  .el-divider {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .el-dialog {
    border-radius: 20px;
  }

  .el-dialog__header {
    padding: 20px 20px 0 24px;
  }

  .el-dialog__body {
    padding: 22px 24px 24px 24px;
  }

  .form-input {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .form-input__input {
    height: 50px;
    border-radius: 200px;
  }

  .form-input__label {
    font-size: 12px;
    font-family: Montserrat, sans-serif;

    span {
      margin-left: 20px;
    }
  }

  .form-select__title-wrapper {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-left: 20px;
  }

  .form-select {
    margin-bottom: 32px;

    .el-select {
      margin-top: 6px;
    }

    .el-input__inner {
      height: 50px;
    }

    .el-select__tags {

      padding-left: 2px;

      .el-tag.el-tag--info {
        display: flex;
        align-items: center;
        height: 34px;

        .el-tag__close {
          border: 1px #FFFFFF solid;
          color: #FFFFFF;
          background: #1454F2;
          position: absolute;
          right: 5px;
          top: 8px;
        }
      }
    }
  }
}

.add-direction-wrapper__select {
  .el-select-dropdown__item {
    font-family: Roboto, sans-serif;
  }

  .el-select-dropdown__item.is-disabled {
    color: #C0C4CC;
  }
}
</style>