import http from '@/app/admin/config/admin-axios.config'
import cancelableWrapper from "../../../utils/cancelableWrapper";

class UserRepository {
    _usersPagePromise = null

    async getCoursesForSelect() {
        const response = await http.get('/courses/full')
        return Promise.resolve(response.data)
    }

    async getUsersPage({page, limit}, sort_by_date_of_registration) {
        if (this._usersPagePromise) this._usersPagePromise.cancel()

        this._usersPagePromise = cancelableWrapper(http.get(`/users`, {params: {limit, page, sort_by_date_of_registration}}))

        const response = await this._usersPagePromise

        return Promise.resolve(response.data)
    }

    async getInfoCourseBy(userId) {
        const response = await http.get(`/users/${userId}/courses`)
        return Promise.resolve(response.data)
    }

    async deleteUser(userId){
        const response = await http.delete(`/users/${userId}`)
        return Promise.resolve(response.data)
    }

    async updateUser(user,userId) {
        const response = await http.patch(`/users/${userId}`,user)
        return Promise.resolve(response.data)
    }

    async changeStatusUser(userId,isBanned){
        const data = await http.patch(`/users/${userId}/ban`,{isBanned})
        return Promise.resolve(data)
    }

    async downloadInfoAllUser(){
        const data = await http.get('/users/export',{responseType: 'blob'})
        return Promise.resolve(data.data)
    }

    async addedCourseForUser(userId,courseIds){
        const data = await http.post(`/courses/user/${userId}`, {courseIds})
        return Promise.resolve(data.data)
    }

    async deleteCourseForUser(userId,courseIds){
        const data = await http.delete(`/courses/${courseIds}/user/${userId}`)
        return Promise.resolve(data.data)
    }


}

export const userRepository = new UserRepository()
