<template>
  <div class="user-modal">
    <base-modal
        v-if="isVisible"
        :visible="isVisible"
        title="Редактировать пользователя"
        :image="require('@/assets/img/admin/dialog-icon-admin.svg')"
        :loading="loading"
        @submit="handleSave"
        @close="handleClose">
      <form @submit.prevent="handleSave">
        <form-input label="Почта"
                    v-model="editUser.email"
                    max-length="40"
                    :disabled="true"
                    :validator="$v.editUser.email"
                    placeholder="Введите электронный адрес"/>
        <form-input label="Имя"
                    v-model="editUser.fullName"
                    :validator="$v.editUser.fullName"
                    max-length="40"
                    placeholder="Введите имя"/>
        <div class="user-modal__select">
          <label class="user-modal__title-select" for="gender-select">Пол</label>
          <el-select popper-class="user-gender-info-select" id="gender-select" v-model="editUser.sex" placeholder="Выберите пол">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="user-modal__date-picker-group">
          <label class="user-modal__title-select" for="date-picker">Дата рождения</label>
          <el-date-picker
              popper-class="user-modal-date-birthday"
              id="date-picker"
              type="date"
              v-model="editUser.birthday"
              placeholder="Выберите дату рождения"
              @change="dateFormatting">
          </el-date-picker>
        </div>

        <form-input v-model="editUser.phoneNumber"
                    label="Номер телефона"
                    disabled
                    placeholder="Номер телефона"/>

        <form-input v-model="editUser.password"
                    label="Пароль"
                    type="password"
                    placeholder="Введите пароль"/>
        <form-input v-model="editUser.location"
                    label="Место проживания"
                    placeholder="Место проживания"/>

      </form>
    </base-modal>
  </div>

</template>

<script>

import BaseModal from "../../../../components/BaseModal";
import FormInput from "@/components/FormInput";

import {validationMixin} from "vuelidate";
import {maxLength, minLength, email, required} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";

export default {
  name: "UserEditModal",
  mixins: [validationMixin],
  components: {
    BaseModal,
    FormInput
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      isVisible: false,
      editUser: {
        email: '',
        fullName: '',
        sex:'',
        birthday: '',
        location:'',
        password:'',
      },

      options: [{
          value: 'male',
          label: 'Мужчина'
        },
        {
          value: 'female',
          label: 'Женщина'
        }],
    }
  },
  methods: {
    openModal: function (user) {
      if(user) this.editUser = {...user,password: ''}

      this.isVisible = true
      this.loading = false
    },
    handleClose: function () {
      this.$emit('onClose')
      this.editUser = {name: '', email: '', birthday: ''}
      this.isVisible = false

      this.$v.$reset()
    },
    handleSave: function () {

      if (validate(this.$v)) {
        this.$emit('onSave',this.editUser)
        this.handleClose()
      }
    },
    dateFormatting(){
      const dateBirthday = new Date(this.editUser.birthday.toString())
      this.editUser.birthday = `${dateBirthday.getFullYear()}-${dateBirthday.getMonth() + 1}-${dateBirthday.getDate()}`
    }
  },
  validations:{
    editUser:{
      email: {email: email},
      fullName: {required, minLength: minLength(1), maxLength: maxLength(64)},
      password: {minLength: minLength(6), maxLength: maxLength(64)},

    }
  }
}
</script>

<style lang="scss" scoped>
.user-modal{
  &__title-input{
    font-family: Roboto ,sans-serif;
    font-size: 14px;
    color: #404040;

    &--error{
      color: red;
    }
  }
  &__form-input{
    position: relative;
    margin: 7px 0px;
  }
  &__error-text {
    position: absolute;
    top: 2px;
    right: 0;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    color: #FF0000;
  }

  &__title-select{
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: Roboto ,sans-serif;
    font-size: 14px;
    color: #404040;
  }

  &__date-picker-group{
    display: flex;
    flex-direction: column;

    margin: 7px 0px;
  }
}
</style>

<style lang="scss">
.user-modal{
  .el-date-editor {
    margin-top: 6px;
    width: 100%;
  }

  &__select {
    .el-select {
      margin-top: 7px;
      width: 100%;
    }
  }
}
.user-modal-date-birthday{
  .el-date-picker__header{
    font-family: Montserrat,sans-serif;
  }
  .el-picker-panel__content{
    font-family: Montserrat,sans-serif;
  }
}

</style>
