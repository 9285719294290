<template>
  <div class="admin-control">
    <div class="admin-control__header">
      <div class="admin-control__header-title">Управление администраторами</div>
      <add-button @click.native="handleClickAddAdmin">Добавить администратора</add-button>
    </div>
    <slot/>
    <data-tables-server class="admins-table"
                        :data="admins"
                        v-loading="loading"
                        :total="total"
                        :key="tableKey"
                        :pagination-props="{ pageSizes: [5, 10, 20], background: true }"
                        @query-change="changePagination">
      <el-table-column
          label="E-mail"
          width="auto"
          prop="email">
      </el-table-column>
      <el-table-column
          label="Имя"
          width="auto"
          prop="fullName">
      </el-table-column>
      <el-table-column label="Действия" width="120px">
        <template slot-scope="props">
          <div class="admins-table__tools" v-if="props.row.email !== 'superadmin@dimatech.org'">
            <div class="admins-table__edit-item" @click="handleEditAdmin(props.row)">
              <img src="@/assets/img/admin/icon-edit.svg">
            </div>
            <app-popover @click-yes="handleRemoveAdmin(props.row.id)">
              <p slot="title">Вы точно хотите удалить?</p>
              <div slot="icon" class="admins-table__delete-item"
                   v-if="meData.id !== props.row.id">
                <img src="@/assets/img/admin/icon-delete.svg">
              </div>
            </app-popover>
          </div>
        </template>
      </el-table-column>
    </data-tables-server>

    <admin-action-modal
        ref="adminActionModal"
        :loading="loadingModal"
        @onSave="handleSave"/>
  </div>
</template>

<script>
import AddButton from "../components/AddButton";
import AdminActionModal from "./components/AdminActionModal";
import AppPopover from "../../../components/AppPopover";

import {adminService} from "./admin.service";

export default {
  name: "AdminAdminsTable",
  components: {
    AddButton,
    AdminActionModal,
    AppPopover
  },
  computed: {
    meData() {
      return this.$store.state.admin.info
    }
  },
  data() {
    return {
      admins: [],
      loading: true,
      loadingModal: false,
      pagination: {page: 1, limit: 20,},
      total: 0,
      tableKey: 0,
    }
  },
  methods: {
    handleClickAddAdmin() {
      this.$refs.adminActionModal.openModal(null, 'add')
    },
    handleEditAdmin: function (admin) {
      this.$refs.adminActionModal.openModal(admin, 'update')
    },
    handleRemoveAdmin(id) {
      adminService.deleteAdmin(id).then(() => this.getUsersPage())
    },
    handleSave(actionModal) {
      this.loadingModal = true
      if (actionModal.mode === 'add') {
        adminService.addAdmin(actionModal.admin)
            .then(() => {
              this.$refs.adminActionModal.handleClose()
            })
            .finally(() => {
              this.loadingModal = false
              this.getUsersPage()
            })
      } else {
        this.loading = true
        adminService.updateAdmin(actionModal.admin)
            .then(data => {
              const index = this.admins.findIndex(item => item.id === data.id)
              Object.keys(data).forEach(key => this.admins[index][key] = data[key])
              this.$refs.adminActionModal.handleClose()
            })
            .finally(() => {
              this.loadingModal = false
              this.loading = false
            })
      }
    },

    getUsersPage: async function () {
      this.loading = true
      adminService.getAdminsPage(this.pagination)
          .then(data => {
            this.admins = data.data
            this.total = data.total
          })
          .finally(() => this.loading = false)
    },
    changePagination: function (query) {
      this.pagination.page = query.page
      this.pagination.limit = query.pageSize

      this.getUsersPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-control {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;

    margin-bottom: 12px;

    &-title {
      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #2A2A2A;
    }

    &-add-user-btn {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;

      background: #1454F2;
      border: 2px solid #1454F2;
      box-sizing: border-box;
      border-radius: 120px;

      width: 290px;
      height: 55px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

</style>
