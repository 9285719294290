<template>
  <div class="admin-control">
    <div class="admin-control__header">
      <div class="admin-control__header-title">Управление пользователями</div>
    </div>
    <div class="admin-control__header-buttons">
      <slot/>
    </div>
    <data-tables-server class="users-table"
                        v-loading="loading"
                        :data="users"
                        :total="total"
                        :key="tableKey"
                        :pagination-props="{ pageSizes: [5, 10, 20], background: true }"
                        @query-change="changePagination">
      <el-table-column
          label="E-mail"
          width="290px"
          prop="email">
      </el-table-column>
      <el-table-column
          label="Имя"
          width="230px"
          prop="fullName">
      </el-table-column>
      <el-table-column
          label="Номер телефона"
          width="145px">
        <template slot-scope="props">
          <div v-if="props.row.phoneNumber">
            {{props.row.phoneNumber}}
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="Дата рождения" width="130px">
        <template slot-scope="props">
          <div v-if="props.row.birthday">
            {{ new Date(props.row.birthday).toLocaleDateString() }}
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="Дата регистрации" width="165px" prop="dateOfRegistration" sortable="custom">
        <template slot-scope="props">
          <div v-if="props.row.dateOfRegistration">
            {{ new Date(props.row.dateOfRegistration * 1000).toLocaleDateString() }}
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="Забанен" prop="isBanned">
        <template slot-scope="props">
          <el-switch
              v-model="props.row.isBanned"
              active-color="#4EE1A3"
              inactive-color="#d0d0d0"
              @change="changeStatusUser(props.row)"/>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="38px">

        <template slot-scope="props">
          <div class="users-table__show-courses">
            <div class="users-table__show-courses-header">
              <img src="@/assets/img/admin/users/icon-mortarboard.svg" alt="icon">
              <span>Курсы пользователя</span>
            </div>
            <el-table class="info-courses-table"
                      :data="props.row.courses"
                      style="width: 100%">
              <el-table-column label="Название курса"
                                width="450px">
                <template slot-scope="props">
                  <div class="users-table__show-courses-item">
                    <img v-if="props.row.addedManually" src="@/assets/img/admin/users/icon-admin.svg" alt="icon">
                    <i v-else class="users-table__show-courses-delimiter"/>
                    {{props.row.name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="Цена"
                  width="160px">
                <template slot-scope="props">
                  <span v-if="props.row.price !== null && !props.row.addedManually">{{props.row.price/100}} &#8381;</span>
                  <span v-else>Добавлен вручную</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="Дата покупки"
              width="160px">
                <template slot-scope="props">
                  {{ new Date(props.row.purchaseDate * 1000).toLocaleDateString() }}
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="props">

                    <app-popover @click-yes="deleteCourseForUser(props.row.userId, props.row.courseId)">
                      <p slot="title">Вы уверены, что хотите удалить курс?</p>
                      <div slot="icon" >
                        <div class="users-table__delete-course-btn">
                          <img src="@/assets/img/admin/icon-delete.svg">
                        </div>
                      </div>
                    </app-popover>

                </template>

              </el-table-column>

            </el-table>
          </div>

          <button @click="addCourseForUser(props.row)" class="users-table__add-course-btn">
            Добавить курс
          </button>
        </template>

      </el-table-column>
      <el-table-column label="Действия" width="110px">
        <template slot-scope="props">
          <div class="users-table__tools">
            <div class="users-table__edit-item" @click="handleEditUser(props.row)">
              <img src="@/assets/img/admin/icon-edit.svg">
            </div>
            <app-popover @click-yes="handleRemoveUser(props.row.id)">
              <p slot="title">Удалить пользователя?</p>
              <div slot="icon" class="admins-table__delete-item">
                <div class="users-table__delete-item">
                  <img src="@/assets/img/admin/icon-delete.svg">
                </div>
              </div>
            </app-popover>
          </div>
        </template>

      </el-table-column>

    </data-tables-server>
    <user-edit-modal ref="UserEditModal"
                     :loading="loadingModal"
                     @onSave="handleSave"/>
    <add-course-for-user ref="addCourseForUser" @addCourse="addCourses"/>
  </div>
</template>

<script>
import AppPopover from "../../../components/AppPopover";
import UserEditModal from "./components/UserEditModal";
import {userService} from "./user.service";
import AddCourseForUser from "./components/AddCourseForUser";

export default {
  name: "AdminUsersTable",
  components: {
    UserEditModal,
    AppPopover,
    AddCourseForUser
  },
  data() {
    return {
      users: [],
      sort_by_date_of_registration: null,
      loading: true,
      loadingModal: false,
      pagination: {page: 1, limit: 20,},
      total: 0,
      tableKey: 0,
    }
  },
  methods: {
    handleEditUser(user) {
      this.$refs.UserEditModal.openModal(user)
    },
    handleRemoveUser(id) {
      this.loading = true
      userService.deleteUser(id)
          .then(() => this.getUsersPage())
          .finally(() => this.loading = false)

      this.tableKey++
    },
    async handleSave(editUser) {
      this.loadingModal = true

      await userService.updateUser(editUser).then(data => {
        const index = this.users.findIndex(item => item.id === data.id)
        Object.keys(data).forEach(key => this.users[index][key] = data[key])
      })
          .finally(() => {
            this.loadingModal = false
          })

      await this.getUsersPage()

      this.tableKey++
    },

    getUsersPage: async function () {
      this.loading = true

      await userService.getUsersPage(this.pagination, this.sort_by_date_of_registration).then(data => {

            data.data.forEach(data=>{
              data.courses = data.courses.map(course => {
                return {
                  ...course,
                  userId: data.id
                }})
              })

            this.users = data.data
            this.total = data.total
          })
          .finally(() => this.loading = false)
    },
    changePagination: function (query) {
      if(query.sort.prop === 'dateOfRegistration')
        this.sort_by_date_of_registration = query.sort.order === "ascending" ? "ASC" :
                                            query.sort.order === "descending" ? "DESC" : null;

      this.pagination.page = query.page
      this.pagination.limit = query.pageSize
      this.getUsersPage()
    },
    changeStatusUser(row) {
      userService.changeStatusUser(row.id, row.isBanned)
    },
    addCourseForUser(userInfo){
      this.$refs.addCourseForUser.openModal(userInfo)
    },
    addCourses(data){
      userService.addedCourseForUser(data.userId, data.courseIds).then((response)=>{
        const indexUser = this.users.findIndex(user => user.id === data.userId)

        response.forEach(course => {
          this.users[indexUser].courses.push({
            ...course,
            userId: data.userId
          })
        })

      })
    },
    deleteCourseForUser(userId, courseId){
      userService.deleteCourseForUser(userId, courseId).then(()=>{
        const indexUser = this.users.findIndex(user => user.id === userId)
        const indexCourse = this.users[indexUser].courses.findIndex(course => course.courseId === courseId)
        this.users[indexUser].courses.splice(indexCourse,1)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.users-table {
  &__tools {
    display: flex;
  }

  &__show-courses {

  }

  &__show-courses-header {
    display: flex;
    align-items: center;

    margin-bottom: 10px;

    transition: 0.2s;

    span {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #1454F2;

      margin-left: 12px;
    }
  }

  &__show-courses-item {
    display: flex;
    align-items: center;

    padding-left: 20px;
    margin-bottom: 4px;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #121212;

    img {
      margin-left: -3px;
      margin-right: 6px;
    }
  }
  &__show-courses-delimiter {
    margin-right: 12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #1454F2;
  }

  &__add-course-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 160px;
    height: 40px;

    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;

    background: #407BFF;
    border-radius: 41px;
    margin-left: 20px;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      background: #1454F2;
    }
  }
  &__edit-item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;

    margin-right: 10px;

    background: #407BFF;
    border-radius: 2px;

    cursor: pointer;
  }

  &__delete-course-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 26px;
    height: 26px;

    background: #FF6969;
    border-radius: 2px;
    margin: 0 auto;
    cursor: pointer;

    img {
      height: 14px;
    }
  }

  &__delete-item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;

    background: #FF6969;
    border-radius: 2px;

    cursor: pointer;
  }
}

.admin-control {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;

    margin-bottom: 12px;

    &-title {
      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #2A2A2A;
    }

    &-buttons {
      display: flex;
    }
    &-add-user-btn {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;

      background: #1454F2;
      border: 2px solid #1454F2;
      box-sizing: border-box;
      border-radius: 120px;

      width: 290px;
      height: 55px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

</style>

<style lang="scss">
.users-table {
  .el-table {

    .cell {
      word-break: break-word;
    }

    th.is-leaf {
      border: none;
    }

    td:first-child {
      padding-left: 20px;
    }

    th:first-child {
      padding-top: 20px;
      padding-left: 20px;
    }

    &__header {
      thead {
        th {
          padding-top: 20px;
          padding-bottom: 8px;
        }

        th .cell {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;

          color: #2A2A2A;
        }

        th:last-child .cell {
          font-weight: 400;
        }
      }
    }

    &__row {
      td:first-child .cell {
        font-weight: 500;
      }

      td .cell {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: #121212;
      }

      td.el-table__expand-column .cell {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 32px;
        height: 32px;

        margin-right: 32px;

        background: #749FFF;
        border-radius: 2px;

        cursor: pointer;

        transition: 0.2s;

        &:hover {
          background: #1454F2;
        }

        .el-table__expand-icon {
          height: auto;
          color: #FFFFFF;
          font-size: 20px;

          &--expanded {
            transform: none
          }

          i {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 32px;
            height: 32px;

            position: relative;
            margin: 0px;
            top: 0;
            left: 0;
          }
        }

        .el-icon-arrow-right:before {
          content: "\e6d5";
        }
      }
    }

    &__body {
      margin-bottom: 20px;
    }

    &__body-wrapper {
      border-radius: 4px;
      min-height: 450px;
      background: #FBFBFB;

      tr {
        background: #FBFBFB;
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    td {
      border-bottom: 1px solid #FFFFFF;
    }

    &::before {
      height: 0px;
    }
  }

  .el-table__expanded-cell {
    padding-left: 30px !important;
    background: #FBFBFB;
  }

  .el-pagination {
    margin: 30px 0px;


    .el-select .el-input {
      width: 130px;
      margin: 0 5px;
    }
  }
}
.info-courses-table{

  left: -10px;

  .el-table__body-wrapper {
    min-height: min-content;
  }

  tr:hover > td {
    background-color: #FBFBFB !important;

  }

  th {
    background: #FBFBFB;
    &:hover, &:focus{
      background: #FBFBFB;
      border: none !important;
    }
  }
  thead th .cell {
    font-size: 13px !important;
  }
  td {
    padding: 4px  0 6px 0;
  }

  i{
    background: #1454F2 !important;
    &:hover {
      background: #1454F2 !important;

    }
  }
  .users-table__show-courses-item{
    padding-left: 0;
  }
}
</style>
