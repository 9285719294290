<template>
  <div class="admin-modal">
    <base-modal
        :visible="isVisible"
        :title="title"
        :image="require('@/assets/img/admin/dialog-icon-admin.svg')"
        :loading="loading"
        @submit="handleSave"
        @close="handleClose">
      <template>
        <form-input label="Имя"
                    v-model="newAdmin.fullName"
                    max-length="40"
                    :validator="$v.newAdmin.fullName"
                    placeholder="Введите имя"/>
        <form-input v-if="mode==='add'"
                    label="Email"
                    v-model="newAdmin.email"
                    type="email"
                    :validator="$v.newAdmin.email"
                    max-length="40"
                    placeholder="Введите email"/>
        <form-input label="Пароль"
                    type="password"
                    :validator="$v.newAdmin.password"
                    v-model="newAdmin.password"
                    max-length="40"
                    placeholder="Введите пароль"/>
      </template>
    </base-modal>
  </div>

</template>

<script>

import BaseModal from "../../../../components/BaseModal";
import FormInput from "@/components/FormInput";

import {validationMixin} from "vuelidate";
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";

export default {
  name: "AdminActionModal",
  mixins: [validationMixin],
  components: {
    BaseModal,
    FormInput
  },
  props: {
    loading: Boolean,
  },
  computed: {
    title() {
      if (this.mode === 'add') return 'Добавить администратора'
      else return 'Редактировать администратора'
    },
  },
  data() {
    return {
      isVisible: false,
      mode: '',
      newAdmin: {
        fullName: '',
        email: '',
        password: '',
      },
    }
  },
  methods: {
    openModal: function (admin, mode) {
      if (admin) this.newAdmin = {...admin}

      this.isVisible = true

      this.mode = mode
    },
    handleClose: function () {
      this.newAdmin = {fullName: '', email: '', password: ''}
      this.isVisible = false
      this.$v.$reset()
    },
    handleSave: function () {
      if (validate(this.$v)) {
        this.$emit('onSave', {admin: this.newAdmin, mode: this.mode})
      }
    },
  },
  validations() {
    const validationsMap = {
      fullName: {required, minLength: minLength(2), maxLength: maxLength(25)},
      password: {minLength: minLength(6), maxLength: maxLength(64)},
    }

    if (this.mode === 'add') {
      validationsMap.email = {required, email}
      validationsMap.password.required = required
    }

    return {
      newAdmin: {
        ...validationsMap
      }
    }
  }
}
</script>

<style scoped>

</style>
