<template>
  <div class="admin-control">
    <admin-users-table v-if="flagTab==='1'">
      <el-radio v-model="flagTab" label="1">Пользователи</el-radio>
      <el-radio v-model="flagTab" label="2">Администраторы</el-radio>
      <div @click="exportInfoUsers" class="download-users-info">
        <img src="@/assets/img/admin/users/icon-download.svg" alt="download">
        Скачать excel
      </div>
    </admin-users-table>
    <admin-admins-table v-else>
      <el-radio v-model="flagTab" label="1">Пользователи</el-radio>
      <el-radio v-model="flagTab" label="2">Администраторы</el-radio>
    </admin-admins-table>
  </div>
</template>

<script>
import AdminUsersTable from "@/app/admin/adminUsers/AdminUsersTable";
import AdminAdminsTable from "@/app/admin/adminUsers/AdminAdminsTable";
import {adminService} from "./admin.service";
import {userService} from "./user.service";

export default {
  name: "AdminUsersControl",
  components: {
    AdminUsersTable,
    AdminAdminsTable,
  },
  data() {
    return {
      flagTab:'1',
    }
  },
  methods:{
    exportInfoUsers(){
      userService.downloadInfoAllUser()
    },
    handleClickAddAdmin(){
      this.handleEditAdmin(null)
    },
     handleEditAdmin: function (admin) {
      this.editAdmin = admin
      this.isShowAdminModal = true
    },
    handleClose: function () {
      this.isShowAdminModal = false
      this.editAdmin = null
    },
    handleRemoveAdmin(){

    },
    handleCloseAdminModal: function (data) {
      if (data) {
        if (data.mode === 'created') {
          this.tableData.unshift(data.data)
        } else if (data.mode === 'updated') {
          const index = this.admins.findIndex(item => item.id === data.data.id)
          Object.keys(data.data).forEach(key => {
            this.admins[index][key] = data.data[key]
          })
        }
      }
      this.editAdmin = null
      this.isShowAdminModal = false
    },
    handleDeleteAdmin: function (categoryId) {
      this.loading = true
      adminService.deleteCategoryById(categoryId)
          .then(() => {
            const index = this.tableData.findIndex(item => item.id === categoryId)
            this.tableData.splice(index, 1)
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<style lang="scss">
.admin-control{
  .el-radio {
    &__inner{
      width: 24px;
      height: 24px;
      background: #FFFFFF;
    }
    &__label{
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      color: #2A2A2A;
    }
  }
  .el-radio.is-checked{
    .el-radio {
      &__label{
        color: #2A2A2A;
      }
      &__inner{
        background: #FFFFFF;

        &:after{
          width: 10px;
          height: 10px;
          background: #1454F2;
        }
      }
    }
  }

  .download-users-info {
    display: flex;
    align-items: center;
    justify-content: center;


    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;

    width: 169px;
    height: 44px;
    margin-left: auto;
    cursor: pointer;

    background: #4EE1A3;
    border-radius: 41px;

    > img {
      margin-right: 10px;
    }
  }
}
</style>
