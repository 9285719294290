import {notificationsHelper} from '@/helpers/notifications.helper'
import {userRepository} from "./user.repository";
// import {objectCamelToUnderscore} from "../../../utils/HTTPDataParser";
import {prepareData} from "@/utils/prepareData";

class UserService {


    async getCoursesForSelect() {
        try {
            const courses = await userRepository.getCoursesForSelect()
            return courses.map(course => {
                return {
                    id: course.id,
                    name: course.name,
                    price: course.price,
                    date_of_registration: new Date().toDateString()
                }
            })
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async getUsersPage(pagination, sort_by_date_of_registration) {
        try {
            const userInfo = await userRepository.getUsersPage(pagination, sort_by_date_of_registration)
            return {data: userInfo.data, total: userInfo.total}
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async getInfoCourseBy(userId) {
        const courses = await userRepository.getInfoCourseBy(userId)
        return Promise.resolve(courses)
    }

    async deleteUser(userId) {
        try {
            const data = await userRepository.deleteUser(userId)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async updateUser(user) {
        try {

            const body = prepareData(user,
                [
                    {key: 'birthday', rule: 'emptyStringNull'},
                    'fullName',
                    {key: 'location', rule: 'emptyStringNull'},
                    {key: 'password', rule: 'emptyStringDelete'},
                    {key: 'sex', rule: 'emptyStringNull'}
                ])

            const data = await userRepository.updateUser(body, user.id)
            return Promise.resolve(data)

        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async changeStatusUser(userId, isBanned) {
        try {
            const data = await userRepository.changeStatusUser(userId, isBanned)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async downloadInfoAllUser() {
        const file = await userRepository.downloadInfoAllUser()
        const fileLink = document.createElement('a')

        fileLink.href = window.URL.createObjectURL(file)
        fileLink.setAttribute('download', 'user info')
        fileLink.click()
    }

    async addedCourseForUser(userId, courseIds) {
        try {
            const data = await userRepository.addedCourseForUser(userId, courseIds)
            return data
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: 'Данный курс не существует', backend: false})
            return Promise.reject(error)
        }
    }

    async deleteCourseForUser(userId, courseIds) {
        try {
            const data = await userRepository.deleteCourseForUser(userId, courseIds)
            return Promise.resolve(data.data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: 'У пользователя нет этого курса или он не существует', backend: false})
            return Promise.reject(error)
        }
    }

}

export const userService = new UserService()
